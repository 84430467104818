import { v4 as uuidv4 } from "uuid";

import { database } from "@/firebaseConfig";
import { httpsCallable, getFunctions } from "firebase/functions";
import {
  collection,
  getDoc,
  doc,
  onSnapshot,
  deleteDoc,
  setDoc,
  arrayUnion,
  updateDoc,
  arrayRemove,
  Timestamp,
} from "firebase/firestore";
import {
  IProfile,
  IReportPortfolio,
  ISharedProfileLink,
} from "@/types/formInfo.interface";
import { generateRandomId } from "@/utils/generateRandomId";

// Get the Cloud Function reference
const functions = getFunctions();
const viewSharedProfileCluodFunction = httpsCallable(
  functions,
  "viewSharedProfile"
);

const viewPublicProfileCluodFunction = httpsCallable(
  functions,
  "viewPublicProfile"
);

const reportPortfolioCloudFunction = httpsCallable(
  functions,
  "reportPortfolio"
);

const getSharedListProfiles = httpsCallable(functions, "getSharedListProfiles");
const sharePortfolioViaEmailCloudFunction = httpsCallable(
  functions,
  "sharePortfolioViaEmail"
);

export class ProfileServices {
  static async getTalentProfile(uid: string) {
    try {
      const talentDocRef = doc(database, "talents", uid);
      const talentDocSnap = await getDoc(talentDocRef);

      if (talentDocSnap.exists()) {
        return talentDocSnap.data();
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching talent profile:", error);
      return null;
    }
  }
  static async getprofileData(
    uid: string,
    profileId: string,
    setUserData: (userData: IProfile) => void,
    setLoading?: (loading: boolean) => void
  ) {
    try {
      setLoading && setLoading(true);
      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);

      // Add an onSnapshot listener to talentRef
      const talentUnsubscribe = onSnapshot(talentRef, (talentSnapshot) => {
        const docData = talentSnapshot.data();

        // Create a reference to the profiles collection within the talent document
        const profilesRef = collection(talentsRef, `${uid}/profiles`);
        const profileRef = doc(profilesRef, profileId || uid);

        const unsubscribe = onSnapshot(profileRef, (snapshot) => {
          const userData = snapshot.data() as IProfile;
          setLoading && setLoading(false);

          // Combine data from talent document and profile document
          setUserData({ ...userData, ...docData });
        });

        // Return both unsubscribe functions
        return () => {
          unsubscribe();
          talentUnsubscribe();
        };
      });
    } catch (error) {
      setLoading && setLoading(true);
      console.log(error);
    }
  }

  static async getRecruiterProfileData(uid: string) {
    if (uid) {
      try {
        const recruiterRef = collection(database, "recruiters");
        const profileRef = doc(recruiterRef, uid);
        const profileDoc = await getDoc(profileRef);

        return await profileDoc.data();
      } catch (error) {
        console.log(error);
      }
    }
  }

  static async copyPorfile(
    profileName: string,
    currentPorfileData: IProfile,
    uid: string,
    setNewProfileId: (id: string) => void
  ) {
    const {
      industryExperiences,
      certificates,
      achievements,
      jobFunctions,
      experiences,
      educations,
      hardSkills,
      introVideo,
      aboutWords,
      softSkills,
      languages,
      userId,
      title,
      bio,
    } = currentPorfileData;
    try {
      const profileId = uuidv4();
      const talentsRef = collection(database, "talents");
      const userRef = doc(talentsRef, userId);
      const profileRef = doc(talentsRef, uid, "profiles", profileId);
      setNewProfileId(profileId);
      await setDoc(
        profileRef,
        {
          // id: profileId,
          profileName: profileName,
          profileId: profileId,
          industryExperiences: industryExperiences || [],
          certificates: certificates || [],
          achievements: achievements || [],
          jobFunctions: jobFunctions || [],
          experiences: experiences || [],
          educations: educations || [],
          hardSkills: hardSkills || [],
          introVideo: introVideo || "",
          aboutWords: aboutWords || [],
          softSkills: softSkills || [],
          languages: languages || [],
          userId,
          title,
          bio: bio || "",
        },
        { merge: true }
      );
      return await setDoc(
        userRef,
        {
          profiles: arrayUnion(
            ...[
              {
                name: profileName,
                id: profileId,
              },
            ]
          ),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async removeProfile(profileId: string, uid: string) {
    try {
      const talentsRef = collection(database, "talents");
      const profileRef = doc(talentsRef, uid, "profiles", profileId);

      await deleteDoc(profileRef);

      const userRef = doc(talentsRef, uid);

      const profileDoc = await getDoc(userRef);

      const profiles = profileDoc.data()?.profiles;

      const filterdProfiles = profiles.filter(
        (profile: any) => profile.id != profileId
      );

      return await setDoc(
        userRef,
        {
          profiles: filterdProfiles,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async shareProfile(linkInfo: ISharedProfileLink) {
    const {
      name,
      id,
      uid,
      expirationDate,
      note,
      password,
      profileId,
      sharedProfileId,
    } = linkInfo;
    try {
      const talentLinksRef = collection(database, "sharedProfiles");
      const userRef = doc(talentLinksRef, sharedProfileId);

      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);

      const data = {
        id,
        name,
        profileId,
        uid,
        expirationDate,
        note,
        password,
      };

      await setDoc(
        talentRef,
        {
          sharedProfiles: arrayUnion(data),
        },
        {
          merge: true,
        }
      );
      return await setDoc(
        userRef,
        {
          links: arrayUnion(data),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async saveSharedEmail(
    uid: string,
    linkId: string,
    sharedProfileId: string,
    info: { fullName?: string; email: string }[]
  ) {
    try {
      const talentLinksRef = collection(database, "sharedProfiles");
      const userRef = doc(talentLinksRef, sharedProfileId);
      const userData = await getDoc(userRef);

      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);

      if (userData.exists()) {
        const updatedLinks = userData.data().links.map((link: any) => {
          const emails = info.map((item) => {
            return {
              ...item,
              createdAt: Timestamp.now(),
            };
          });
          if (link.id === linkId) {
            return {
              ...link,
              sharedEmails: [...emails, ...(link.sharedEmails ?? [])],
            };
          } else {
            return link;
          }
        });

        await setDoc(
          talentRef,
          {
            sharedProfiles: updatedLinks,
          },
          {
            merge: true,
          }
        );

        return await updateDoc(userRef, {
          links: updatedLinks,
        });
      } else {
        throw new Error("User data not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async editShareLink(linkInfo: ISharedProfileLink) {
    const {
      name,
      id,
      uid,
      expirationDate,
      note,
      password,
      profileId,
      sharedProfileId,
      sharedEmails,
    } = linkInfo;
    try {
      const talentLinksRef = collection(database, "sharedProfiles");
      const userRef = doc(talentLinksRef, sharedProfileId);
      const userData = await getDoc(userRef);

      const talentsRef = collection(database, "talents");
      const talentRef = doc(talentsRef, uid);

      if (userData.exists()) {
        const updatedLinks = userData.data().links.map((link: any) => {
          if (link.id === id) {
            return {
              ...link,
              id,
              name,
              profileId,
              uid,
              expirationDate,
              note,
              password: password,
            };
          } else {
            return link;
          }
        });

        await setDoc(
          talentRef,
          {
            sharedProfiles: updatedLinks,
          },
          {
            merge: true,
          }
        );

        return await updateDoc(userRef, {
          links: updatedLinks,
        });
      } else {
        throw new Error("User data not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async viewSharedPorfile(linkInfo: {
    sharedProfileId: string;
    id: string;
    password: string;
  }) {
    return viewSharedProfileCluodFunction(linkInfo).then((result) => {
      return result.data as IProfile;
    });
  }

  static async getSharedProfileLinks(sharedProfileId: string) {
    try {
      const sharedProfilesRef = collection(database, "sharedProfiles");
      const talentLinksRef = doc(sharedProfilesRef, sharedProfileId);
      const docData = await getDoc(talentLinksRef);
      if (docData.exists()) {
        return await docData.data()?.links;
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  static async removeShareLink(
    linkDetail: ISharedProfileLink,
    sharedProfileId: string
  ) {
    try {
      const talentLinksRef = collection(database, "sharedProfiles");
      const userRef = doc(talentLinksRef, sharedProfileId);
      const userData = await getDoc(userRef);

      const talentsRef = await collection(database, "talents");
      const talentRef = await doc(talentsRef, linkDetail.uid);

      if (userData.exists()) {
        const updatedLinks = userData
          .data()
          .links.filter((link: any) => link.id !== linkDetail.id);
        await setDoc(
          talentRef,
          {
            sharedProfiles: arrayRemove(linkDetail),
          },
          {
            merge: true,
          }
        );
        return await updateDoc(userRef, {
          links: updatedLinks,
        });
      } else {
        throw new Error("User data not found");
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async createSharedProfileId(uid: string) {
    try {
      const profilesRef = collection(database, "talents");
      const userRef = doc(profilesRef, uid);
      return await setDoc(
        userRef,
        {
          sharedProfileId: generateRandomId(6),
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }
  }

  static async getSharedListProfiles(sharedListData: {
    userId: string;
    sharedListId: string;
    password: string;
  }) {
    return getSharedListProfiles(sharedListData).then((result: any) => {
      return result.data;
    });
  }
  static async sharePortfolioViaEmail(
    linkInfo: {
      uid: string;
      id: string;
      sharedProfileId: string;
    },
    data: any
  ) {
    await sharePortfolioViaEmailCloudFunction(data).then(() => {
      return this.saveSharedEmail(
        linkInfo.uid,
        linkInfo.id,
        linkInfo.sharedProfileId,
        data?.users
      );
    });
  }

  static async viewPublicPorfile(info: { profileId: string; uid: string }) {
    return viewPublicProfileCluodFunction(info).then((result) => {
      return result.data as IProfile;
    });
  }

  static async reportPortfolio(info: IReportPortfolio) {
    return reportPortfolioCloudFunction(info).then((result) => {
      return result.data;
    });
  }
}
