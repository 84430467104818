import { useUser } from "@/context/AuthContext";
import { IAccessLevelRecruiter } from "@/types/formInfo.interface";

export function useFeatureAccess(featureRoles: IAccessLevelRecruiter[]) {
  const { currentUser } = useUser();

  const hasAccess =
    currentUser?.userType === "recruiter" &&
    featureRoles.includes(currentUser?.accessLevel);

  return { hasAccess };
}
